import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMenu } from './menuHeaderAPI';

const initialState = {
  title: '',
  menuItems: [],
  status: '',
  selectedMenuItem: null,

};

export const getMenuAsync = createAsyncThunk(
  'menuHeader/fetchMenu',
  async (url) => {
    const response = await fetchMenu(url);
    return response.data;
  }
);

export const menuHeaderSlice = createSlice({
  name: 'menuHeader',
  initialState: initialState,
  reducers: {
    setSelectedMenuItem : (state, action) => {
        state.selectedMenuItem = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenuAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMenuAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.title = action.payload.title;
        state.menuItems = action.payload.menuItems;
      });
  },
});

export const { setSelectedMenuItem } = menuHeaderSlice.actions;

export const selectTitle = (state) => state.menuHeader.title;
export const selectMenuItems = (state) => state.menuHeader.menuItems;
export const selectSelectedMenuItem = (state) => state.menuHeader.selectedMenuItem;

export default menuHeaderSlice.reducer;
