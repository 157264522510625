import React from 'react';
import 'bulma/css/bulma.min.css'
import './App.css';
import Person from './features/people/Person';

function App() {
  return (
    <>
        <Person/>
    </>
  );
}

export default App;
