// A mock function to mimic making an async request for data
export function fetchMenu(url = '') {
    return new Promise((resolve) =>
      setTimeout(() => resolve({ data: 
        {
            title:'My Title',
            menuItems:[
                {name: 'Home', to: '/'},
                {name: 'About', to: '/about'},
                {name: 'Contact', to: '/contact'},
                {name: 'CV', to: '/cv'},
                {name: 'Submenu',to: '', subitems:[
                    {name: 'Detail', to:'/detail'},
                    {name: 'Skills', to:'/skills'},
                ]}
            ]
        } }), 500)
    );
  }