import React, { useState, useEffect } from 'react';

export default function Person() {
    const [person, setPerson] = useState(null);
      
    const getPerson = () => {
        var personInfoUrl = '/data/info.json'

        fetch(personInfoUrl)
        .then(res => res.json())
        .then(data => {
          setPerson(data);
        });

    };


    useEffect(() => {
      getPerson();
    }, []);



    return (<>
    
      
    <section className="container">

      <div className="hero">
        <div className="hero-body">
          <div className="container">

            <div className="columns">

              <div className="column has-text-centered">

                  <div className='columns'>
                    <div className='column'></div>
                    <div className='column'>
                      <figure className="image is-128x128 has-text-centered">
                        <img className="is-rounded" src={person && person.picture} alt="Profile Picture"/>
                      </figure>
                    </div>
                    <div className='column'></div>
                  </div>                
                
                <h1 className="title is-4">{person && person.firstName} {person && person.lastName}</h1>
                <h5 className="subtitle is-6">{person && person.title} <small>({person && person.position})</small></h5>
              </div>

              <div className="column">
                
                <div className="content">
                  <h2 className="section-heading">Contact</h2>
                  <p>Email: {person && person.email}</p>
                  <p>Phone: {person && person.phone}</p>
                  <p>Location: {person && person.location} </p>
                  <p>LinkedIn: <a href={person && person.linkedin} target="_blank">{person && person.linkedin}</a></p>
                  <p>GitHub: <a href={person && person.github} target="_blank">{person && person.github}</a></p>
                  <p>Web: <a href={person && person.web} target="_blank">{person && person.web}</a></p>
                  
                </div>
                
              </div>


            </div>

          </div>
        </div>
      </div>

      {/*END Person general info--- */}

      {/* BEGIN Profile--- */}

      <div className="content">
        <h2 className="section-heading">Profile</h2>
        <p className="has-text-justified">
          <ul>
              {person && person.profile
              ? person.profile.map((p, index)=>{
                return(<>
                  <li key={index}>
                    {p}
                  </li>
                </>);
              })
              :''}
          </ul>	
        </p>
      </div>      

      {/* END Profile--- */}

      {/* BEGIN SKILLS--- */}


      <div className="content">
        <h2 className="section-heading field is-grouped is-grouped-multiline">
			
        Skills &nbsp;&nbsp;&nbsp;
        
        
          <div className="control">
          <div className="tags has-addons">
            <span className="tag is-dark is-small">Skill</span>
            <span className="tag is-info is-small">years of experience</span>
          </div>
          </div>			
        
        </h2>
            

					<br/>	

        <div className="field is-grouped is-grouped-multiline">

              {person 
              ? person.skills.map((skill, index)=>{
                  return(<>
                    <div key={index} className="control">
                      <div className="tags has-addons">
                        <span className="tag is-dark">{skill.name}</span>
                        <span className="tag is-info">{skill.years}</span>
                      </div>
                    </div>
                  </>);
              })
              :''}
          
        </div>
		  </div>      

      {/* END SKILSS--- */}

      {/* Experience */}

      <div className="pagebreak"/>

      <div className="content">

        <h2 className="section-heading">Experience</h2>

        <div className="timeline">

          {
            person && person.experience.map((exp, index)=>{
            return(<>
          <div className="timeline-item" key={index}>
            <p className="heading">{exp.period}</p>
            <p>
              <strong>{exp.position}</strong>
              &nbsp;at {exp.company}
            </p>
            <p className="has-text-justified">{exp.description}</p>
            <p> 
              <strong>Skills:</strong>
              {exp.skills 
              ? exp.skills.map((skillId, idx) => {
                return(<>

                  <small key={index+'-'+idx}>&nbsp;{idx==0?'':', '}{person.skills.find(skill => skill.id === skillId).name}</small>

                </>);
              })
              : ""}
            </p>
          </div>

            
            
            </>)
            })
          }
        </div>  

      </div>
      
        
      {/* END Experience */}

      {/* Education */}

      <div className="content">
        <h2 className="section-heading">Education</h2>

          {person && person.education.map((edu, index)=>{

            return(<>
              <div className="timeline" key={index}>
                <div className="timeline-item">
                  <p className="heading">{edu.period}</p>
                  <p>
                    <strong>{edu.title}</strong>
                  </p>
                  <p>{edu.school}</p>
                </div>
              </div>

            </>);
          })}

      </div>      

      {/* END Education */}

      {/* Certification */}

      <div className="content">
        <h2 className="section-heading">Certifications</h2>

        {person && person.certifications.map((cer, index)=>{


          return(<>

            <div className="timeline-item" key={index}>

                  <p className="heading">{cer.title}</p>
                  <p>
                    <a href={cer.url} target="_blank">{cer.url}</a>
                  </p>
                  <p>
                      {
                        cer.imageUrl 
                        ? (<>
                            <img src={cer.imageUrl} alt="certificate badge  " width="89"/>
                          </>) 
                        : ''
                      }
                  </p>                  
                  <p>
                      {
                        cer.notes 
                        ? (<>
                          <small>
                            <i>Note: please use yoadad@gmail email to check the certifications</i>
                          </small>
                          </>) 
                        : ''
                      }
                  </p>
            </div>          
          
          </>);

        })}
      </div>      

      {/* END Certification */}
    </section>
    
    </>);


}