import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import menuHeaderReducer from '../features/menuHeader/menuHeaderSlice'; 

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    menuHeader: menuHeaderReducer
  },
});
